import React from "react";

import { Fade } from "react-reveal";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const home_image2 = require("../../../../assets/images/home/home_image2.png");
const home_image3 = require("../../../../assets/images/home/home_image3.png");
const home_image4 = require("../../../../assets/images/home/home_image4.png");

const DiscoverFeatures = () => {
  const windowDimensions = useWindowDimension();

  return (
    <Fade bottom>
      <div id="discoverFeatures" className="discoverFeatures">
        <img
          src={home_image2}
          width={windowDimensions.width * 0.2375}
          height={windowDimensions.width * 0.2281}
          alt="home 2"
          className="discoverFeatures-mobile "
        />
        <img
          src={home_image3}
          width={
            windowDimensions.width > 600 ? windowDimensions.width * 0.5 : "80%"
          }
          height={
            windowDimensions.width > 600
              ? windowDimensions.width * 0.3518
              : "auto"
          }
          alt="home 3"
        />
        <img
          src={home_image4}
          width={windowDimensions.width * 0.2375}
          height={windowDimensions.width * 0.27}
          alt="home 4"
          className="discoverFeatures-mobile "
        />
      </div>
    </Fade>
  );
};

export default DiscoverFeatures;
