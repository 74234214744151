import React, { useState, useEffect } from "react";

import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import "./styles.css";

const PrivacyPolicy = (props) => {
  // This is done to import tailwind only to this file, so as not to cause issues elsewhere
  require("tailwindcss/tailwind.css");
  const [privacyPolicyData, setPrivacyPolicyData] = useState(null);
  const [error, setError] = useState(false);

  const fetchPrivacyPolicyData = async () => {
    console.log("Fetching Privacy Policy");
    const response = await fetch(
      "https://api.app.visualc3.com/privacy-policy",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      }
    );
    if (response.status !== 200) {
      setError(true);
      return;
    }
    const data = await response.json();
    setPrivacyPolicyData(data);
    return data;
  };

  const tryAgain = () => {
    setError(false);
    fetchPrivacyPolicyData();
  };

  useEffect(() => {
    fetchPrivacyPolicyData();
  }, []);

  return (
    <div className="privacypolicy_container">
      <div className="twp">
        <article class="prose lg:prose-xl dark dark:prose-invert">
          <div>{privacyPolicyData ? privacyPolicyData.title : "Loading"}</div>
          <div>
            {privacyPolicyData
              ? `Effective Date: ${privacyPolicyData.effectiveDate.substring(
                  0,
                  10
                )}`
              : ""}
          </div>
          {privacyPolicyData ? (
            <ReactMarkdown>{privacyPolicyData.body}</ReactMarkdown>
          ) : (
            ""
          )}
          {error ? <button onSubmit={tryAgain}>Try Again</button> : ""}
        </article>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
