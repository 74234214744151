import React from "react";

import { Link } from "react-router-dom";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";

const PageNotFound = (props) => {
  return (
    <div className="pageNotFound_container">
      <header className="pageNotFound_header">
        <Link to="/" className="pageNotFound_Link">
          <LogoSVG width={300} />
        </Link>
      </header>
      <div className="pageNotFound_404_container">
        <h1 className="pageNotFound_404">404</h1>
      </div>
      <div className="pageNotFound_title">
        <span className="h1 text-white">Oops...</span>
        <span className="h1 text-white">Page not found!</span>
      </div>
      <Link to="/" className="pageNotFound_Link">
        <div className={`gotoHome`}>
          <span className="h6 text-white" color={props.color}>
            Home
          </span>
        </div>
      </Link>
    </div>
  );
};

export default PageNotFound;
