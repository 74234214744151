import React from "react";

const Banner = (props) => {
  // This is done to import tailwind only to this file, so as not to cause issues elsewhere
  require("tailwindcss/tailwind.css");

  return (
    <div className="twp">
      <div className="flex justify-center pb-5 px-5 md:px-0">
        <a
          href={props.link}
          className={`bg-white py-5 px-10 rounded mx-auto flex flex-row items-center hover:underline`}
        >
          <div className="pr-5">{props.icon}</div>
          <div className="flex flex-col items-center text-center">
            <span className="h2 text-slate-900">{props.title}</span>
            <span className="h3 text-slate-900 italic">{props.subtitle}</span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Banner;
