// analytics.js
import Analytics from "analytics";
import googleAnalytics from "@analytics/google-analytics";

const myPlugin = {
  name: "visualc3-website-analytics-plugin",
  page: ({ payload }) => {
    // console.log("Page view fired: ", payload);
  },
  track: ({ payload }) => {
    // console.log("Track event payload: ", payload);
  },
};

const analyticsInstance = Analytics({
  app: "visualc3",
  plugins: [
    myPlugin,
    googleAnalytics({
      measurementIds: ["G-0BP21667B4"],
    }),
  ],
});

export default analyticsInstance;
