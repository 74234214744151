import React, { useEffect, useState } from "react";

import { Fade } from "react-reveal";

import TextField from "@mui/material/TextField";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";
import K9Video from "../../assets/videos/K9.mp4";

const K9Contest = (props) => {
  // This is done to import tailwind only to this file, so as not to cause issues elsewhere
  require("tailwindcss/tailwind.css");
  const [emailAddress, setEmailAddress] = useState("");
  const [handlerName, setHandlerName] = useState("");
  const [handlerYears, setHandlerYears] = useState("");
  const [k9Name, setK9Name] = useState("");
  const [k9Years, setK9Years] = useState("");
  const [agency, setAgency] = useState("");

  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const k9activitytracking_image4 = require("../../assets/images/k9activitytracking/k9activitytracking_image4.png");

  const submitHandler = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true);
      // Based on format of reachmail api: https://services.reachmail.net/#resources/Lists/SubscriptionForm/Subscriber
      const data = {
        Properties: [
          {
            ListElementName: "email",
            Value: emailAddress,
          },
          {
            ListElementName: "WEBHOUSE4_handler_name",
            Value: handlerName,
          },
          {
            ListElementName: "WEBHOUSE4_handlers_years_of_service",
            Value: handlerYears,
          },
          {
            ListElementName: "WEBHOUSE4_k9_name",
            Value: k9Name,
          },
          {
            ListElementName: "WEBHOUSE4_k9_years_of_service",
            Value: k9Years,
          },
          {
            ListElementName: "WEBHOUSE4_agency",
            Value: agency,
          },
        ],
        MailingFormat: 0,
      };
      await fetch(
        "https://services.reachmail.net/lists/SubscriptionForm/subscriber/58c8dc50-2c81-4494-9602-b1d800ea7fd8",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({
            ...data,
          }),
        }
      ).then((response) => {
        console.log("Response:", response);
        setIsLoading(false);
        if (response.status === 200) {
          window.location = "/form-success";
        }
        if (response.status === 404) {
          console.log("Script not found");
          window.location = "/form-failure";
        }
      });
    } catch (e) {
      console.log("Contact Error:", e);
      setIsLoading(false);
      window.location = "/form-failure";
    }
  };

  // Basic form validation
  useEffect(() => {
    if (
      emailAddress &&
      handlerName &&
      handlerYears &&
      k9Name &&
      k9Years &&
      agency
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [emailAddress, handlerName, handlerYears, k9Name, k9Years, agency]);

  return (
    <>
      {isLoading && (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      )}
      <Fade bottom>
        <div className="twp">
          <div className="container mx-auto flex flex-col items-center">
            <div className="py-5">
              <Link to="/" className="logo">
                <LogoSVG width={300} />
              </Link>
            </div>
            <div className="flex items-center flex-col lg:flex-row">
              {/* K9 Hero Text */}
              <div className="w-2/3 px-5">
                <p className="text-white text-center pb-5 text-4xl lg:text-8xl">
                  We are looking for our{" "}
                  <span className="text-blue-400 whitespace-nowrap">
                    K-9 Heroes!
                  </span>
                </p>
                <p className="italic text-white text-center pb-5 text-xl lg:text-2xl">
                  In partnership with{" "}
                  <span className="text-blue-400 whitespace-nowrap">
                    Law Enforcement Today
                  </span>
                </p>
                <p className="text-white text-center text-2xl lg:text-4xl">
                  Win free access to our{" "}
                  <span className="text-gray-400">K-9 Activity Tracker</span>, a{" "}
                  <span className="text-gray-400">Free Dog Grooming</span>, and
                  see your story shared with millions of people!
                </p>
              </div>
              {/* K9 Hero Image */}
              <div className="w-1/3">
                <img src={k9activitytracking_image4} alt="Hero" />
              </div>
            </div>
            {/* K9 Contest Form */}
            <div id="rmSubscriptionForm" class="rmSubscriptionFormBody">
              <div className="form-container px-5">
                <form
                  name="rm_signup_form"
                  id="rm_signup_form"
                  className="flex flex-1 flex-col gap-5 max-w-xl form-container"
                >
                  <p className="text-2xl text-white text-center">
                    Contest Entry Form
                  </p>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      fullWidth
                      value={emailAddress}
                      onChange={(v) => setEmailAddress(v.currentTarget.value)}
                      disabled={isLoading}
                      sx={{
                        color: "#ffffff",
                        "&:-webkit-autofill": {
                          WebkitBoxShadow: "0 0 0 100px #03051F inset",
                          WebkitTextFillColor: "ffffff",
                        },
                      }}
                    />
                  </fieldset>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="WEBHOUSE4_handler_name"
                      label="Handler's Name"
                      variant="outlined"
                      fullWidth
                      value={handlerName}
                      onChange={(v) => setHandlerName(v.currentTarget.value)}
                      disabled={isLoading}
                    />
                  </fieldset>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="WEBHOUSE4_handlers_years_of_service"
                      label="Handler's Years of Service"
                      variant="outlined"
                      fullWidth
                      value={handlerYears}
                      onChange={(v) => setHandlerYears(v.currentTarget.value)}
                      disabled={isLoading}
                    />
                  </fieldset>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="WEBHOUSE4_k9_name"
                      label="K9 Name"
                      variant="outlined"
                      fullWidth
                      value={k9Name}
                      onChange={(v) => setK9Name(v.currentTarget.value)}
                      disabled={isLoading}
                    />
                  </fieldset>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="WEBHOUSE4_k9_years_of_service"
                      label="K9 Years of Service"
                      variant="outlined"
                      fullWidth
                      value={k9Years}
                      onChange={(v) => setK9Years(v.currentTarget.value)}
                      disabled={isLoading}
                    />
                  </fieldset>
                  <fieldset>
                    <TextField
                      className="form-field"
                      id="WEBHOUSE4_agency"
                      label="Agency"
                      variant="outlined"
                      fullWidth
                      value={agency}
                      onChange={(v) => setAgency(v.currentTarget.value)}
                      disabled={isLoading}
                    />
                  </fieldset>
                  <fieldset>
                    <div className="flex flex-col items-center">
                      <div className="requestDemo_hero_privacy_policy text-center pb-5">
                        <span className="h6-m text-white">
                          We respect your data. By submitting this form, you
                          agree that we can contact you in relation to our
                          products and services, in accordance with our{" "}
                        </span>
                        <Link to="/privacy-policy">
                          <span className="h6-m text-primary">
                            Privacy policy.
                          </span>
                        </Link>
                      </div>
                      <button
                        onClick={submitHandler}
                        disabled={isDisabled || isLoading}
                        className={`w-1/2 requestDemo_hero_submit ${
                          isDisabled ? "requestDemo_hero_disabledSubmit" : ""
                        }`}
                      >
                        <span className="h2-m text-white" color={props.color}>
                          Submit
                        </span>
                      </button>
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
            <div className="flex flex-col lg:flex-row border-y-indigo-400 border-y-2 w-full items-center py-10 my-10 lg:mb-20">
              {/* Video */}
              <div className="m-5 border-2 lg:w-1/2">
                <video
                  width={"100%"}
                  playsInline
                  autoPlay
                  loop
                  muted
                  src={K9Video}
                ></video>
              </div>
              {/* K9 Info */}
              <div className="flex flex-col px-5">
                <p className="text-4xl py-2 text-center lg:text-justify lg:text-6xl whitespace-nowrap">
                  <span className="text-blue-400">K-9</span> Activity Tracker
                </p>
                <p className="text-xl py-2">
                  - Keep accurate records of all training and documentation
                </p>
                <p className="text-xl py-2">
                  - Maintain chain of custody for training and certifications
                </p>
                <p className="text-xl py-2">
                  - Reliable and authentic records that can withstand scrutiny
                </p>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default K9Contest;
