import React, { useState } from "react";

import { Fade } from "react-reveal";

import "./styles.css";

const Hero = () => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Fade bottom>
      <div className="company_hero_container">
        <span className="display text-white">The smartest sensor is</span>
        <span
          className="company_hero_title_container"
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <span
            className={`display text-white company_hero_title_animation ${
              isHovered ? "hover company_here_title_hover" : ""
            }`}
          >
            You
          </span>
          <span className="display text-white company_hero_title_text">
            You
          </span>
        </span>
      </div>
    </Fade>
  );
};

export default Hero;
