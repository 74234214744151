import React from "react";

import "./styles.css";
import { Fade } from "react-reveal";

const Pictures = () => {
  return (
    <Fade bottom>
      <div className="company_pictures_container">
        <div className="company_pictures_row1">
          <div className="company_pictures_img1"></div>
          <div className="company_pictures_img2"></div>
          <div className="company_pictures_img3"></div>
          <div className="company_pictures_img4"></div>
        </div>
        <div className="company_pictures_row2">
          <div className="company_pictures_img5"></div>
          <div className="company_pictures_img6"></div>
        </div>
        <div className="company_pictures_row3">
          <div className="company_pictures_img7"></div>
          <div className="company_pictures_img8"></div>
        </div>
      </div>
    </Fade>
  );
};

export default Pictures;
