import React, { useEffect, useState, useRef } from "react";

import { Fade } from "react-reveal";
import { Link } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";

const Contact = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const contactHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const token = await recaptchaRef.current.executeAsync();
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: workEmail,
        message: message,
      };
      await fetch("https://submit-form.com/HWGyzpGp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...data,
          "g-recaptcha-response": token,
        }),
      }).then((response) => {
        console.log("Response:", response);
        setIsLoading(false);
        if (response.status === 200) {
          window.location = "/form-success";
        }
        if (response.status === 404) {
          console.log("Script not found");
          window.location = "/form-failure";
        }
      });
    } catch (e) {
      console.log("Contact Error:", e);
      setIsLoading(false);
      window.location = "/form-failure";
    }
  };

  useEffect(() => {
    if (firstName && lastName && workEmail && message) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [firstName, lastName, workEmail, message]);

  return (
    <>
      {isLoading && (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      )}
      <Fade bottom>
        <div className="contact_hero_container">
          <div className="contact_hero_image" />
          <div className="contact_hero_logo">
            <Link to="/" className="logo">
              <LogoSVG width={300} />
            </Link>
          </div>
          <div className="contact_hero_inputContainer">
            <div className="contact_hero_input">
              <span className="h2-sb text-white contact_hero_input_title">
                Contact us
              </span>
              <TextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(v) => setFirstName(v.currentTarget.value)}
                disabled={isLoading}
              />
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(v) => setLastName(v.currentTarget.value)}
                disabled={isLoading}
              />
              <TextField
                id="outlined-basic"
                label="Work email"
                variant="outlined"
                fullWidth
                value={workEmail}
                onChange={(v) => setWorkEmail(v.currentTarget.value)}
                disabled={isLoading}
              />
              <TextField
                id="outlined-basic"
                label="Message"
                variant="outlined"
                fullWidth
                value={message}
                multiline
                maxRows={5}
                minRows={5}
                onChange={(v) => setMessage(v.currentTarget.value)}
                disabled={isLoading}
              />
              <div className="contact_hero_privacy_policy">
                <span className="h6-m text-white text-opacity">
                  We respect your data. By submitting this form, you agree that
                  we will contact you in relation to our products and services,
                  in accordance with our{" "}
                </span>
                <Link to="/privacy-policy">
                  <span className="h6-m text-primary">Privacy policy</span>
                </Link>
              </div>
              <button
                disabled={isDisabled || isLoading}
                onClick={contactHandler}
                className={`contact_hero_submit ${
                  isDisabled ? "contact_hero_disabledSubmit" : ""
                }`}
              >
                <span className="h2-m text-white" color={props.color}>
                  Submit
                </span>
              </button>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                badge="inline"
                sitekey="6Lf5Qd4eAAAAAGZJr2Ri1s_fENGpxJOVDy1tgCkj"
              ></ReCAPTCHA>
            </div>
          </div>
        </div>
      </Fade>
    </>
  );
};

export default Contact;
