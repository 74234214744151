import React from "react";

import { Fade } from "react-reveal";

import K9Video from "../../../../assets/videos/K9.mp4";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const Hero = () => {
  const windowDimensions = useWindowDimension();
  return (
    <Fade bottom>
      <div className="enterprise_hero_container">
        <p className="title-b text-white enterprise_hero_text">
          K9 Activity Tracking
        </p>
        <video
          width={windowDimensions.width * 0.92625}
          height={windowDimensions.width * 0.52125}
          autoPlay
          loop
          muted
          src={K9Video}
        ></video>
      </div>
    </Fade>
  );
};

export default Hero;
