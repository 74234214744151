import React from "react";

import { Fade } from "react-reveal";

import {
  InnovationSVG,
  TeamworkSVG,
  IntegritySVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const company_image1 = require("../../../../assets/images/company/company_image1.png");

const Values = () => {
  const windowDimensions = useWindowDimension();
  const Item = ({ icon, title, description }) => {
    return (
      <div className="company_values_list_item">
        <div className="company_values_list_item_title">
          {icon}
          <span className="h2-sb text-white">{title}</span>
        </div>
        <span className="h3-m text-white text-opacity">{description}</span>
      </div>
    );
  };

  return (
    <Fade bottom>
      <div id="values" className="company_values_container">
        <img
          src={company_image1}
          width={windowDimensions.width}
          height={windowDimensions.width * 0.10625}
          alt="Hero"
        />
        <div className="company_values_title">
          <span className="h2-sb text-white">CORE VALUES</span>
        </div>
        <div className="company_values_list_container">
          <div className="company_values_list_row">
            <Item
              icon={<InnovationSVG />}
              title="Innovation"
              description="We encourage new ideas and constantly strive to exceed expectations."
            />
            <Item
              icon={<TeamworkSVG />}
              title="Teamwork"
              description="We value each other's experiences and perspectives to communicate openly."
            />
            <Item
              icon={<IntegritySVG />}
              title="Integrity"
              description="We conduct ourselves with the highest level of integrity and honor our commitments."
            />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Values;
