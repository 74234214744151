import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  MaintenanceSVG,
  SafetySVG,
  LegalAndRegulatorySVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const propertymanagement_image1 = require("../../../../assets/images/propertymanagement/propertymanagement_image1.png");
const propertymanagement_image2 = require("../../../../assets/images/propertymanagement/propertymanagement_image2.png");
const propertymanagement_image3 = require("../../../../assets/images/propertymanagement/propertymanagement_image3.png");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Real-time notifications for maintenance and security issues.",
    "Alerts for potential maintenance problems (water leaks, hazards, electrical issues).",
    "Proactive identification and addressing of maintenance issues.",
    "Minimize repair costs and prevent disruptions.",
  ];
  const communicationChallenges = [
    "Enhance safety and security with GuardMe safety monitoring solution.",
    "GuardMe SOS button for quick assistance in emergencies.",
    "Mitigate risks and protect occupants.",
  ];
  const isolationAndMentalHealth = [
    "Documentation and audit trails for security incidents and maintenance.",
    "Historical data storage for property conditions, maintenance, safety incidents.",
    "Demonstrate compliance with building codes, safety standards, regulations.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<MaintenanceSVG />}
            title="Maintenance and Repairs"
            description={safetyconcerns}
            image={propertymanagement_image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<SafetySVG />}
            title="Safety Concerns"
            description={communicationChallenges}
            image={propertymanagement_image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<LegalAndRegulatorySVG />}
            title="Legal and Regulatory Compliance"
            description={isolationAndMentalHealth}
            image={propertymanagement_image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
