import React from "react";

import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const Birth = () => {
  const windowDimensions = useWindowDimension();
  const appInfos = [
    {
      title: "11+ years",
      description: "of creating safety solutions.",
    },
    {
      title: "+1k GuardMe sessions",
      description: "Countless hours worth of peace of mind.",
    },
    {
      title: "+1k reported incidents",
      description: "Myriad of processes streamlined.",
    },
  ];

  const InfoItem = ({ title, description }) => {
    return (
      <div
        className="InfoItemContainer"
        style={{
          maxWidth:
            windowDimensions.width > 860
              ? windowDimensions.width * 0.26
              : windowDimensions.width * 0.8,
        }}
      >
        <p className="h2-sb infoItemString">{title}</p>
        <p className="h2-m infoItemString1">{description}</p>
      </div>
    );
  };

  return (
    <div id="statistics" className="birthInfoContainer">
      {appInfos.map((appInfo, index) => {
        return (
          <InfoItem title={appInfo.title} description={appInfo.description} />
        );
      })}
    </div>
  );
};

export default Birth;
