import React from "react";

import { Fade } from "react-reveal";

import {
  ContactUsSVG,
  GetInstructionSVG,
  StaySafeSVG,
} from "../../../../assets/svgs";
import { Button } from "../../../../components";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const loneworkers_image4 = require("../../../../assets/images/loneworkers/loneworkers_image4.png");

const Ability = () => {
  const windowDimensions = useWindowDimension();

  return (
    <Fade bottom>
      <div className="enterprise_ability_container">
        <img
          src={loneworkers_image4}
          width={
            windowDimensions.width > 860
              ? windowDimensions.width * 0.205625
              : windowDimensions.width > 600
              ? "80%"
              : "100%"
          }
          height={
            windowDimensions.width > 860
              ? windowDimensions.width * 0.365
              : "auto"
          }
          alt="Hero"
        />
        <div className="enterpise_ability_list_container">
          <div className="enterpise_ability_list_item">
            <div className="icon_container">
              <ContactUsSVG />
            </div>
            <p
              onClick={() => (window.location = "/contact")}
              className="underline  h2-m"
            >
              Contact us
            </p>
          </div>
          <div className="enterpise_ability_list_item">
            <div className="icon_container">
              <GetInstructionSVG />
            </div>
            <p className=" h2-m">Get instructions for further steps</p>
          </div>
          <div className="enterpise_ability_list_item">
            <div className="icon_container">
              <StaySafeSVG />
            </div>
            <p className=" h2-m">Stay safe with See It Send It</p>
          </div>
          <div className="enterprise_request_demo">
            <Button label="REQUEST DEMO" link="/request-demo" color="black" />
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Ability;
