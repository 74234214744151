import { useEffect } from "react";

import { useAnalytics } from "use-analytics";
import { useLocation } from "react-router-dom";

import AppNav from "./routes";
import { Footer, Header } from "./components";

import "./App.css";

function App() {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    // send page view on route change
    analytics.page();
  }, [location, analytics]);

  return (
    <div className="App">
      <Header />
      <AppNav />
      <Footer />
    </div>
  );
}

export default App;
