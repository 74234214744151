import React from "react";

import { Fade } from "react-reveal";

import "./styles.css";

const Mission = () => {
  return (
    <Fade bottom>
      <div className="company_mission_container">
        <div className="company_mission_text_container">
          <p className="h1-m">
            <span style={{ color: "white", opacity: "60%" }}>Our mission</span>{" "}
            is to empower organizations to make informed decisions, enhance
            their operational efficiency, and strengthen their overall security
            posture.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default Mission;
