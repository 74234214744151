import React from "react";

import { Fade } from "react-reveal";

import { Button } from "../../../../components";
import HomeVideo from "../../../../assets/videos/home.MP4";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const home_image5 = require("../../../../assets/images/home/home_image5.png");
const home_image6 = require("../../../../assets/images/home/home_image6.png");
const home_image7 = require("../../../../assets/images/home/home_image7.png");
const home_image8 = require("../../../../assets/images/home/home_image8.png");
const home_image9 = require("../../../../assets/images/home/home_image9.png");
const home_image10 = require("../../../../assets/images/home/home_image10.png");

const DiscoverFeatures = () => {
  const windowDimensions = useWindowDimension();

  return (
    <Fade bottom>
      <div
        id="requestDemo"
        className="requestDemo_container"
        data-aos="fade-up"
        data-aos-anchor-placement="top-bottom"
      >
        <img
          src={home_image5}
          width={
            windowDimensions.width > 600
              ? windowDimensions.width * 0.92375
              : "100%"
          }
          alt="home 5"
        />
        <div className="top_images">
          <img
            src={home_image6}
            width={windowDimensions.width * 0.1381}
            height={windowDimensions.width * 0.13032}
            alt="home 6"
            className="requestDemo-mobile-images"
          />
          <img
            src={home_image7}
            width={windowDimensions.width * 0.13875}
            height={windowDimensions.width * 0.1309}
            alt="home 7"
            className="requestDemo-mobile-images"
          />
        </div>
        <div className="center_images">
          <img
            src={home_image8}
            width={windowDimensions.width * 0.23375}
            height={windowDimensions.width * 0.333819}
            alt="home 8"
            className="requestDemo-mobile-images"
          />
          <div className="center_text">
            <p className="h1-m">
              <span style={{ color: "white", opacity: "60%" }}>
                See It Send It
              </span>{" "}
              is your dependable companion, empowering you to maintain safety
              and control effortlessly. Stay connected and conquer any challenge
              that comes your way.
            </p>
            <Button
              label="REQUEST DEMO"
              link=""
              styles="homeHeroRequestDemo"
              color="black"
            />
          </div>
          <img
            src={home_image9}
            width={windowDimensions.width * 0.195}
            height={windowDimensions.width * 0.2943}
            alt="home 9"
            className="requestDemo-mobile-images"
          />
        </div>
        <div className="bottom_images">
          <img
            src={home_image6}
            width={windowDimensions.width * 0.1318}
            height={windowDimensions.width * 0.1303}
            alt="home 6"
            className="requestDemo-mobile-images"
          />
          <img
            src={home_image10}
            width={windowDimensions.width * 0.1517}
            height={windowDimensions.width * 0.1432}
            alt="home 10"
            className="requestDemo-mobile-images"
          />
        </div>
        <video
          width={
            windowDimensions.width > 600
              ? windowDimensions.width * 0.871875
              : "100%"
          }
          height={
            windowDimensions.width > 600
              ? windowDimensions.width * 0.559375
              : "auto"
          }
          autoPlay
          playsInline
          loop
          muted
          src={HomeVideo}
        ></video>
      </div>
    </Fade>
  );
};

export default DiscoverFeatures;
