import React from "react";

import "./styles.css";

const Button = (props) => {
  return (
    <a href={props.link} className={`mainButton ${props.styles}`}>
      <span className="h4-b" style={{ color: props.color }}>
        {props.label}
      </span>
    </a>
  );
};

export default Button;
