import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  WorkplaceViolenceSVG,
  WorkplaceSafetySVG,
  EmployeeWellSVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const workplacesafety_image1 = require("../../../../assets/images/workplacesafety/workplacesafety_image1.png");
const workplacesafety_image2 = require("../../../../assets/images/workplacesafety/workplacesafety_image2.png");
const workplacesafety_image3 = require("../../../../assets/images/workplacesafety/workplacesafety_image3.png");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Incident reporting feature for reporting violence or aggression.",
    "Detailed documentation for investigations.",
    "Real-time alerts to designated personnel.",
    "Immediate responses to potential violent situations.",
    "Analysis of incident data for proactive prevention measures.",
  ];
  const communicationChallenges = [
    "Reporting safety hazards triggers timely responses.",
    "Management addresses issues before accidents occur.",
    "Platform tracks resolution of reported hazards.",
    "Ensures compliance with safety regulations.",
    "Analysis of safety incidents identifies training needs and enhances safety protocols.",
  ];
  const isolationAndMentalHealth = [
    "Easy channel for employees to report concerns.",
    "Fosters trust and openness around workplace safety.",
    "Safety monitoring sessions and SOS button for peace of mind.",
    "Ensures all employees are informed and involved.",
    "Maintains a safe work environment.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<WorkplaceViolenceSVG />}
            title="Workplace violence"
            description={safetyconcerns}
            image={workplacesafety_image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<WorkplaceSafetySVG />}
            title="Workplace safety concerns"
            description={communicationChallenges}
            image={workplacesafety_image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<EmployeeWellSVG />}
            title="Employee well-being and safety"
            description={isolationAndMentalHealth}
            image={workplacesafety_image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
