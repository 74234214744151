import React from "react";

import { Link } from "react-router-dom";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";

const FormSuccess = (props) => {
  return (
    <div className="FormSuccess_container">
      <header className="FormSuccess_header">
        <Link to="/" className="FormSuccess_Link">
          <LogoSVG width={300} />
        </Link>
      </header>
      <div className="FormSuccess_title">
        <span className="h1-m text-white">Successfully submitted form!</span>
      </div>
      <Link to="/" className="FormSuccess_Link">
        <div className={`gotoHome`}>
          <span className="h5-r text-white" color={props.color}>
            Back Home
          </span>
        </div>
      </Link>
    </div>
  );
};

export default FormSuccess;
