import React from "react";

import { Routes, Route } from "react-router-dom";

import {
  Company,
  Contact,
  LoneWorkers,
  PropertyManagement,
  K9ActivityTracking,
  WorkplaceSafety,
  Home,
  PrivacyPolicy,
  RequestDemo,
  TermsOfUse,
  PageNotFound,
  FormSuccess,
  FormFailure,
  K9Contest,
  ElectionSafety,
} from "../pages";

const AppNav = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Home />} />
      <Route path="/company" element={<Company />} />
      <Route path="/lone-workers" element={<LoneWorkers />} />
      <Route path="/property-management" element={<PropertyManagement />} />
      <Route path="/k9-activity-tracking" element={<K9ActivityTracking />} />
      <Route path="/workplace-safety" element={<WorkplaceSafety />} />
      <Route path="/election-safety" element={<ElectionSafety />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/request-demo" element={<RequestDemo />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      <Route path="/terms-of-use" element={<TermsOfUse />} />
      <Route path="/form-success" element={<FormSuccess />} />
      <Route path="/form-failure" element={<FormFailure />} />
      <Route path="/k9-contest" element={<K9Contest />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
};

export default AppNav;
