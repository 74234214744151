import React from "react";

import { Fade } from "react-reveal";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const Journey = () => {
  const windowDimensions = useWindowDimension();
  return (
    <Fade bottom>
      <div className="company_journey_container">
        <div id="our-journey" className="company_journey_text_container">
          <span className="h2-sb text-white company_journey_title">
            Our journey
          </span>
          <p
            className="h2-m"
            style={{
              maxWidth:
                windowDimensions.width > 860
                  ? windowDimensions.width * 0.57
                  : "none",
              margin: "0px",
            }}
          >
            <span style={{ color: "white", opacity: "60%" }}>
              Our name is our vision and mission
            </span>
            , to provide Situational Awareness as a Service (SAaaS)™ and enhance
            the ability to quickly command, control, and communicate. Many of
            our clients operate in some of the most challenging environments
            from frontline transportation workers to domestic violence property
            managers.
          </p>
        </div>
        <div id="about-us" className="company_journey_text_container">
          <span className="h2-sb text-white company_journey_title">
            About Us
          </span>
          <p
            className="h2-m"
            style={{
              maxWidth:
                windowDimensions.width > 860
                  ? windowDimensions.width * 0.57
                  : "none",
              margin: "0px",
            }}
          >
            Dan Kerning founded See It Send It after being approached by
            organizations that needed to{" "}
            <span style={{ color: "white", opacity: "60%" }}>
              improve employee communications and safety.
            </span>{" "}
            As a former Naval Flight Officer, it was an exciting opportunity to
            leverage past experience and develop a suite of mobile applications
            focused on providing situational awareness, tactical management,
            communications and control used by the E-2C Hawkeye aircraft.
          </p>
        </div>
        <div id="birth" className="company_journey_text_container">
          <span className="h2-sb text-white company_journey_title">
            The Birth of See It Send It
          </span>
          <p
            className="h2-m"
            style={{
              maxWidth:
                windowDimensions.width > 860
                  ? windowDimensions.width * 0.57
                  : "none",
              margin: "0px",
            }}
          >
            Over 11 years ago, CloudScann was asked by NJ Transit, as a trusted
            technology partner, to help expand their camera infrastructure by 3x
            with a budget of $0. CloudScann's founder Dan Kerning and his team
            formulated a unique offering to leverage employee's mobile device to
            report various maintenance and security related incidents. This
            solution, coined as "See It Send It", later evolved to include many
            other safety features to help protect employees and staff, such as
            GuardMe and safety Check In's.
          </p>
        </div>
      </div>
    </Fade>
  );
};

export default Journey;
