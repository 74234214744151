import React, { useEffect, useState, useRef } from "react";

import { Fade } from "react-reveal";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import ReCAPTCHA from "react-google-recaptcha";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";

const RequestDemo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [workEmail, setWorkEmail] = useState("");
  const [company, setCompany] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const recaptchaRef = useRef();

  const handleChange = (event) => {
    setCompany(event.target.value);
  };

  const requestDemoHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const token = await recaptchaRef.current.executeAsync();
      const data = {
        firstName: firstName,
        lastName: lastName,
        email: workEmail,
        company: company,
      };
      await fetch("https://submit-form.com/FE1SxhWE", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          ...data,
          "g-recaptcha-response": token,
        }),
      }).then((response) => {
        console.log("Response:", response);
        setIsLoading(false);
        if (response.status === 200) {
          window.location = "/form-success";
        }
        if (response.status === 404) {
          console.log("Script not found");
          window.location = "/form-failure";
        }
      });
    } catch (e) {
      console.log("requestDemo Error:", e);
      setIsLoading(false);
      window.location = "/form-failure";
    }
  };

  useEffect(() => {
    if (firstName && lastName && workEmail && company) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [firstName, lastName, workEmail, company]);

  return (
    <>
      {isLoading && (
        <div className="loading-indicator">
          <CircularProgress />
        </div>
      )}
      <Fade bottom>
        <div className="requestDemo_hero_container">
          <div className="requestDemo_hero_inputContainer">
            <div className="requestDemo_hero_logo">
              <Link to="/" className="logo">
                <LogoSVG width={300} />
              </Link>
            </div>
            <span className="h2-sb text-white requestDemo_hero_input_title">
              Get started with See It Send It
            </span>
            <div className="requestDemo_hero_input">
              <TextField
                id="outlined-basic"
                label="First name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(v) => setFirstName(v.currentTarget.value)}
                disabled={isLoading}
              />
              <TextField
                id="outlined-basic"
                label="Last name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(v) => setLastName(v.currentTarget.value)}
                disabled={isLoading}
              />
              <TextField
                id="outlined-basic"
                label="Work email"
                variant="outlined"
                fullWidth
                value={workEmail}
                onChange={(v) => setWorkEmail(v.currentTarget.value)}
                disabled={isLoading}
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Company size
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={company}
                  label="Company size"
                  onChange={handleChange}
                >
                  <MenuItem value={"1-5"}>1-5</MenuItem>
                  <MenuItem value={"6-50"}>6-50</MenuItem>
                  <MenuItem value={"51-100"}>51-100</MenuItem>
                  <MenuItem value={"101-500"}>101-500</MenuItem>
                  <MenuItem value={"500-1000"}>500-1000</MenuItem>
                  <MenuItem value={"1000+"}>1000+</MenuItem>
                </Select>
              </FormControl>

              <div className="requestDemo_hero_privacy_policy">
                <span className="h6-m text-white">
                  We respect your data. By submitting this form, you agree that
                  we will contact you in relation to our products and services,
                  in accordance with our{" "}
                </span>
                <Link to="/privacy-policy">
                  <span className="h6-m text-primary">Privacy policy</span>
                </Link>
              </div>
              <button
                disabled={isDisabled || isLoading}
                onClick={requestDemoHandler}
                className={`requestDemo_hero_submit ${
                  isDisabled ? "requestDemo_hero_disabledSubmit" : ""
                }`}
              >
                <span className="h2-m text-white" color={props.color}>
                  Request Demo
                </span>
              </button>
              <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                badge="inline"
                sitekey="6Lf5Qd4eAAAAAGZJr2Ri1s_fENGpxJOVDy1tgCkj"
              ></ReCAPTCHA>
            </div>
          </div>
          <div className="requestDemo_hero_image_container">
            <div className="requestDemo_hero_image" />
          </div>
        </div>
      </Fade>
    </>
  );
};

export default RequestDemo;
