import React, { useEffect } from "react";

import { useLocation } from "react-router-dom";

import { Hero, Birth, Journey, Mission, Values, Pictures } from "./components";

import "./styles.css";

const Company = (props) => {
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      if (location.hash) {
        let elem = document.getElementById(location.hash.slice(1));
        if (elem) {
          elem.scrollIntoView({ behavior: "smooth" });
        } else {
          window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
        }
      }
    }, 500);
  }, [location]);

  return (
    <>
      <Hero />
      <Journey />
      <Birth />
      <Values />
      <Mission />
      <Pictures />
    </>
  );
};

export default Company;
