import React from "react";

import { Link } from "react-router-dom";

import { LogoSVG } from "../../assets/svgs";

import "./styles.css";

const FormFailure = (props) => {
  return (
    <div className="FormFailure_container">
      <header className="FormFailure_header">
        <Link to="/" className="FormFailure_Link">
          <LogoSVG width={300} />
        </Link>
      </header>
      <div className="FormFailure_title">
        <span className="h1-m text-white">Request Error</span>
        <span className="h4-m text-white">
          There was an error processing your request! If this is urgent, please
          reach out directly to contact@seeitsendit.com.
        </span>
      </div>
      <Link to="/" className="FormFailure_Link">
        <div className={`gotoHome`}>
          <span className="h5-r text-white" color={props.color}>
            Home
          </span>
        </div>
      </Link>
    </div>
  );
};

export default FormFailure;
