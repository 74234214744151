import React from "react";

import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const AppInfo = () => {
  const windowDimensions = useWindowDimension();
  const appInfos = [
    {
      title: "2.3 annual injury cases",
      description:
        "In 2022, the rate of injury cases was 2.3 per 100 full-time equivalent workers.",
    },
    {
      title: "~5,500 fatal work injuries per year",
      description: "5,486 total fatal work-related injuries in 2022.",
    },
    {
      title: ">71% of workplace assault victims are female",
      description:
        "Between 2021-2022, 71.44% of nonfatal workplace assault cases were female, and resulted in an average of 7 days away from work.",
    },
  ];

  const InfoItem = ({ title, description }) => {
    return (
      <div
        className="InfoItemContainer"
        style={{
          maxWidth:
            windowDimensions.width > 860
              ? windowDimensions.width * 0.26
              : windowDimensions.width * 0.8,
        }}
      >
        <p className="h2-m infoItemString">{title}</p>
        <p className="h3-m infoItemString1">{description}</p>
      </div>
    );
  };

  return (
    <div className="appInfoContainer">
      {appInfos.map((appInfo, index) => {
        return (
          <InfoItem
            key={index}
            title={appInfo.title}
            description={appInfo.description}
          />
        );
      })}
    </div>
  );
};

export default AppInfo;
