import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  LackOfDigitalSVG,
  NoChainOfCustodySVG,
  ReliabilityAndAuthenticitySVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const k9activitytracking_image1 = require("../../../../assets/images/k9activitytracking/k9activitytracking_image1.png");
const k9activitytracking_image2 = require("../../../../assets/images/k9activitytracking/k9activitytracking_image2.png");
const k9activitytracking_image3 = require("../../../../assets/images/k9activitytracking/k9activitytracking_image3.png");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Ranging from obedience training to veterinarian visits.",
    "Traditionally difficult to maintain accurate records.",
    "Challenging to track training progress.",
  ];
  const communicationChallenges = [
    "Issues verifying certifications.",
    "Difficulty documenting training history.",
  ];
  const isolationAndMentalHealth = [
    "Concerns about document accuracy.",
    "Doubts due to human error, faulty memory.",
    "Verification process contributes to doubts.",
    "Necessity for transparency and accountability measures.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<LackOfDigitalSVG />}
            title="Keep accurate records of all training and documentation" // Previously was "Lack of digital documentation for training" hence other component names
            description={safetyconcerns}
            image={k9activitytracking_image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<NoChainOfCustodySVG />}
            title="Maintain chain of custody for training and certifications" // Changes "No Chain" to "Maintain"
            description={communicationChallenges}
            image={k9activitytracking_image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<ReliabilityAndAuthenticitySVG />}
            title="Reliable and authentic records that can withstand scrutiny"
            description={isolationAndMentalHealth}
            image={k9activitytracking_image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
