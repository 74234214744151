import React, { useState } from "react";

import { Fade } from "react-reveal";

import {
  UpSVG,
  DownSVG,
  SafetyconcernsSVG,
  CommunicationChallengesSVG,
  IsolationAndMentalHealthSVG,
} from "../../../../assets/svgs";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const loneworkers_image1 = require("../../../../assets/images/loneworkers/loneworkers_image1.png");
const loneworkers_image2 = require("../../../../assets/images/loneworkers/loneworkers_image2.png");
const loneworkers_image3 = require("../../../../assets/images/loneworkers/loneworkers_image3.png");

const Problem = () => {
  const windowDimensions = useWindowDimension();
  const [selectedItem, setSelectedItem] = useState(-1);

  const safetyconcerns = [
    "Increased risks without immediate assistance or supervision.",
    "Hazards, accidents, emergencies without nearby help.",
  ];
  const communicationChallenges = [
    "Struggles, especially in emergencies or technical issues.",
    "Limited access to colleagues, supervisors delays response.",
  ];
  const isolationAndMentalHealth = [
    "Optimize interactions with managers.",
    "Challenging to identify physical or mental illness signs.",
    "Using communication tools like safety check-ins.",
    "Fulfill OSHA requirements, encourage employees to report symptoms.",
    "Engage in morale and wellness conversations.",
  ];

  const Item = ({
    icon,
    title,
    description,
    image,
    imageWidth,
    imageHeight,
    index,
  }) => {
    return (
      <div
        className={`enterprise_problem_item ${
          selectedItem === index || windowDimensions.width < 601
            ? "expanded"
            : ""
        }`}
      >
        <div
          className="panel-header"
          onClick={() =>
            index === selectedItem
              ? setSelectedItem(-1)
              : setSelectedItem(index)
          }
        >
          <div className="panel-header-left">
            {icon}
            <span className="h2-sb text-white">{title}</span>
          </div>
          <div className="panel-header-right">
            {selectedItem === index ? <UpSVG /> : <DownSVG />}
          </div>
        </div>
        <div className="panel-content">
          <ul>
            {description.map((item) => {
              return (
                <li key={item} className="h2-m">
                  {item}
                </li>
              );
            })}
          </ul>
          <img src={image} width={imageWidth} height={imageHeight} alt="Pic" />
        </div>
      </div>
    );
  };
  return (
    <Fade bottom>
      <div id="problem" className="enterprise_problem_container">
        <span className="h2-sb text-white problem_title">
          Here’s what we can do for you
        </span>
        <div className="enterprise_problem_list">
          <Item
            icon={<SafetyconcernsSVG />}
            title="Safety concerns"
            description={safetyconcerns}
            image={loneworkers_image1}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={0}
          />
          <Item
            icon={<CommunicationChallengesSVG />}
            title="Communication challenges"
            description={communicationChallenges}
            image={loneworkers_image2}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={1}
          />
          <Item
            icon={<IsolationAndMentalHealthSVG />}
            title="Isolation & mental health"
            description={isolationAndMentalHealth}
            image={loneworkers_image3}
            imageWidth={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.27375
                : "90%"
            }
            imageHeight={
              windowDimensions.width > 600
                ? windowDimensions.width * 0.15
                : "auto"
            }
            index={2}
          />
        </div>
      </div>
    </Fade>
  );
};

export default Problem;
