import React from "react";

import { Fade } from "react-reveal";

import { Button } from "../../../../components";
import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const home_image1 = require("../../../../assets/images/home/home_image12.png");

const Hero = () => {
  const windowDimensions = useWindowDimension();

  return (
    <Fade bottom>
      <div id="hero" className="homeHero">
        <div className="homeHeroTitle">
          <span className="h1-m text-white">
            Minimize risks, put safety first.
          </span>
          <span className="h2-m text-white">
            Experience See It Send It, the ultimate security management
            application for your team, assets, and property. Safety starts
            today!
          </span>
          <Button
            label="LEARN MORE"
            link="/company/#about-us"
            styles="homeHeroRequestDemo"
            color="black"
          />
        </div>
        <div className="homeHeroImage">
          <img
            src={home_image1}
            width={windowDimensions.width < 750 ? windowDimensions.width : null} // Only resize image if window smaller than image
            className="heroImage"
            alt="Hero"
          />
        </div>
        <Button
          label="LEARN MORE"
          link="/company/#about-us"
          styles="homeHeroRequestDemoMobile"
          color="black"
        />
      </div>
    </Fade>
  );
};

export default Hero;
