import React, { useState, useEffect, useRef } from "react";

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Transition } from "react-transition-group";
import { Link, useLocation } from "react-router-dom";

import Button from "../Button";
import { DownSVG, LogoSVG } from "../../assets/svgs";

import "./styles.css";

const routes = [
  "/",
  "/company",
  "/company/", // Added this so that footer links to sections on company page still show header
  "/lone-workers",
  "/property-management",
  "/k9-activity-tracking",
  "/workplace-safety",
  "/election-safety",
  "/privacy-policy",
  "/terms-of-use",
];

const Header = (props) => {
  const location = useLocation();

  const trigger = useRef(null);
  const mobileNav = useRef(null);

  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const [isHide, setIsHide] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleNavigate = (value) => {
    setAnchorEl(null);
    window.location = `/${value}`;
  };

  useEffect(() => {
    if (!routes.includes(location.pathname)) {
      setIsHide(true);
    } else {
      setIsHide(false);
    }
  }, [location]);

  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!mobileNav.current || !trigger.current) return;
      if (
        !mobileNavOpen ||
        mobileNav.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setMobileNavOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  const Tab = ({ name, link, color }) => {
    return (
      <li className="tab">
        <a href={link} className={`link`}>
          <span
            className={`h4-m hover ${
              mobileNavOpen ? "text-black" : "text-white"
            } ${location.pathname === link ? "open" : ""}`}
            color={color}
          >
            {name}
          </span>
        </a>
      </li>
    );
  };

  return (
    <>
      {isHide ? null : (
        <header className="header">
          <div className="tabView">
            <ul className="tabs">
              <Tab name="COMPANY" link="/company" />
              <span
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onMouseOver={handleClick}
                // onMouseOut={handleClose}
                className={`h4-m text-white tab ${
                  [
                    "/lone-workers",
                    "/property-management",
                    "/k9-activity-tracking",
                    "/workplace-safety",
                  ].includes(location.pathname)
                    ? "open"
                    : ""
                }`}
              >
                INDUSTRIES
                <DownSVG fill="white" width={30} />
              </span>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem
                  className="menu-item h4-m hover"
                  onClick={() => handleNavigate("lone-workers")}
                >
                  Lone Workers
                </MenuItem>
                <MenuItem
                  className="menu-item h4-m hover"
                  onClick={() => handleNavigate("property-management")}
                >
                  Property Management
                </MenuItem>
                <MenuItem
                  className="menu-item h4-m hover"
                  onClick={() => handleNavigate("k9-activity-tracking")}
                >
                  K9 Activity Tracking
                </MenuItem>
                <MenuItem
                  className="menu-item h4-m hover"
                  onClick={() => handleNavigate("workplace-safety")}
                >
                  Workplace Safety
                </MenuItem>
                <MenuItem
                  className="menu-item h4-m hover"
                  onClick={() => handleNavigate("election-safety")}
                >
                  Election Safety
                </MenuItem>
              </Menu>
            </ul>
            <Link to="/" className="logo">
              <LogoSVG width={150} />
            </Link>
            <ul className="tabs">
              <Tab name="CONTACT US" link="/contact" />
              <Button
                label="REQUEST DEMO"
                link="/request-demo"
                color="black"
                styles="requestDemoMobile"
              />
            </ul>
          </div>
          <div className="mobile-header">
            <Link to="/" className="mobile-logo">
              <LogoSVG width={100} />
            </Link>
            <div
              className={`hamburger ${mobileNavOpen ? "active" : ""}`}
              aria-controls="mobile-nav"
              aria-expanded={mobileNavOpen}
              onClick={() => setMobileNavOpen(!mobileNavOpen)}
            >
              <svg
                viewBox="0 0 24 24"
                width={30}
                height={30}
                fill="white"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect y="4" width="24" height="2" />
                <rect y="11" width="24" height="2" />
                <rect y="18" width="24" height="2" />
              </svg>
            </div>
          </div>
          {mobileNavOpen && (
            <Transition
              show={mobileNavOpen}
              tag="nav"
              className="mobileNavPane"
              enter="transition ease-out duration-200 transform"
              enterStart="opacity-0 -translate-y-2"
              enterEnd="opacity-100 translate-y-0"
              leave="transition ease-out duration-200"
              leaveStart="opacity-100"
              leaveEnd="opacity-0"
            >
              <div className="tabViewMobile">
                <ul className="tabsMobile">
                  <Tab name="COMPANY" link="/company" />
                  <span className="h4-m">INDUSTRIES</span>
                  <div className="mobile-submenu">
                    <Tab name="Lone Workers" link="/lone-workers" />
                    <Tab
                      name="Property Management"
                      link="/property-management"
                    />
                    <Tab
                      name="K9 Activity Tracking"
                      link="/k9-activity-tracking"
                    />
                    <Tab name="Workplace Safety" link="/workplace-safety" />
                    <Tab name="Election Safety" link="/election-safety" />
                  </div>
                  <Tab name="CONTACT US" link="/contact" />
                </ul>
                <Button
                  label="REQUEST DEMO"
                  link="/request-demo"
                  color={"#6172F7"}
                  styles="requestDemoMobile"
                />
              </div>
            </Transition>
          )}
        </header>
      )}
    </>
  );
};

export default Header;
