import React from "react";

import { Fade } from "react-reveal";

import { useWindowDimension } from "../../../../hooks/useWindowDimension";

import "./styles.css";

const SayHello = () => {
  const windowDimensions = useWindowDimension();
  return (
    <Fade bottom>
      <div id="helloToVC3" className="homeSayHello">
        <div className="homeSayHelloTitleContainer h4">
          <p className="homeSayHellowTitle h2-sb text-white">DISCOVER</p>
          <p className="homeSayHellowTitle h2-sb text-white">SEE IT SEND IT</p>
        </div>
        <p
          className="homeSayHelloImageContainer h1-sb"
          style={{
            maxWidth:
              windowDimensions.width < 861
                ? windowDimensions.width * 0.8
                : windowDimensions.width * 0.43,
          }}
        >
          <span style={{ color: "white", opacity: "60%" }}>
            Stay connected and conquer&nbsp;
          </span>
          any challenge ahead. The app that can help save the lives of
          employees.
        </p>
      </div>
    </Fade>
  );
};

export default SayHello;
